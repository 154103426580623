import React from "react";

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-black has-text-white-ter">
        <div className="content has-text-centered">
          <h4>Classic Air Conditioning</h4>
          <span className="footer-small">
          Karnataka | Kerala
          </span>
          <span className="footer-small">
            <p>2021 &copy; classicac.in</p>
          </span>
        </div>
        <div className="content has-text-centered has-background-black has-text-white-ter">
          <div className="container has-background-black has-text-white-ter">
            <div style={{ maxWidth: "100vw" }} className="columns">
              <div className="column is-4">
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;
